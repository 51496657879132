@import "/src/theme/colors";
.incident-report-form {
    background-color: $primaryBackgroundColor;
    width: 100%;
    height: 100%;
    text-align: center;
    color: $fontColor;
    overflow-y: auto;
    padding: 10px;
    &-user {
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      gap: 15px;
      display: flex;
    }
    &-user button {
      font-weight: 600;
      width: 80px;
      height: 30px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: $primaryColor;
      color: white;
    }
    &-user img {
      width: 35px;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $titleColor;
      &-text {
        font-size: 22px;
        font-weight: 600;
        padding-block: 2%;
        padding-inline-start: 5px;
        text-align: start;
      }
      &-search-box { 
        height: 40px;
        width: 40%;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .user-incident {
      &-title {
        flex-direction: column;
        &-text {
          width: 100%;
          font-size: 18px;
        }
        &-search-box{
          width: 100%;
          height: 35px;
        }
      }
    }
  }