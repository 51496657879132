@import '/src/theme/colors';

.question-wrapper {
  padding: 10px;
  border: 1px solid #80808091; 
  border-radius: 10px;
}

.question-main {
  margin-bottom: 1rem;
}

.question-children {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.question-wrapper .radio-input-question {
  border: none !important;
  padding: 0 !important;
}
