@import "/src/theme/colors";

.user-incident-form {
  position: relative;
  min-height: 400px;
  padding: 20px;

  &-content {
    height: 400px; 
    overflow-y: auto; 
    // &::-webkit-scrollbar {
    //   width: 2px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: #c0bbbb;
    //   border-radius: 4px;
    // }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px;

      &-inner {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.17);
        padding: 25px 20px;
        border-radius: 5px;
      }

      &-outer {
        display: flex;
        justify-content: end;
      }
    }
  }
}


.incident-form-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(
    255,
    255,
    255,
    0.9
  ); 
  z-index: 100; 
}
