@import "/src/theme/colors";

.section-content {
  border: 1px solid #80808091;
  border-radius: 10px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: calc(100vh - 50px); 
  overflow-y: auto; 
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }
}

/* This container will hold all the QuestionRenderers in a flex layout */
.section-content-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  &-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.17);
    padding: 25px 20px;
    border-radius: 5px;
  }
  &-outer {
    display: flex;
    justify-content: end;
  }
}

/* Half-width questions take roughly 49% width */
.question-container.half-width,
.question-wrapper.half-width {
  flex: 0 0 calc(50% - 10px);
}

/* Full-width questions span the full row */
.question-container.full-width,
.question-wrapper.full-width {
  flex: 0 0 100%;
}

/* Optionally, if you want the children inside a wrapper to be in a grid */
.question-wrapper .question-children {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

/* Existing override for radio-input if needed */
.question-wrapper .radio-input-question {
  border: none !important;
  padding: 0 !important;
}

@media (max-width: 650px) {
  .question-container.half-width,
  .question-wrapper.half-width {
    flex: 0 0 100%;
  }
}
