@import "/src/theme/colors";

.user-incident-actions-sidebar {
  width: 17%;

  &-nav {
    list-style: none;
    padding: 0;

    &-item {
      padding: 20px;
      cursor: pointer;
      transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
      border-bottom: 1px solid #80808091;
      color: black;
      background-color: white;

      &.active {
        color: black;
        background-color: white;
        border-left: 2px solid $tertiaryColor;
      }

      &:hover {
        background-color: #f5f5f5; // Add a subtle hover effect for better UX
      }
    }
  }
}
