.form-layout {
  display: flex;
  gap: 20px;
  align-items: stretch;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }

  &-nav {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #80808073;
    height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
  }

  &-content {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
    }
  }

  @media (max-width: 1250px) {
    flex-direction: column;

    &-nav {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      flex-direction: row;
      height: auto;
      overflow-x: auto;
    }

    &-content {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}
