@import "/src/theme/colors";

.form-nav {
    border-right: 1px solid #80808073;
    display: flex;
    flex-direction: column;
    color: black;
    height: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 1px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c0bbbb;
        border-radius: 4px;
    }
    &-item {
        display: flex;
        justify-content: start;
        gap: 10px;
        padding: 20px;
        cursor: pointer;
        align-items: center;
        transition: all 0.5s ease;
        min-height: 50px;
        flex-shrink: 0;

        &.active {
            background-color: white;
            color: $titleColor;
            border-right: 2px solid #2b9448;
        }
        &:hover {
            background: white;
            transform: scale(1.02);
        }
        &-number {
            background: #8080803d;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            &.active,
            &.submitted {
                background: #2b9448;
            }
        }
        &-text {
            display: flex;
            flex-direction: column;
            &-name {
            }
            &-info {
                display: none; // Hide by default
            }
            text-align: left;
        }
    }
}

@media (max-width: 1250px) {
    .form-nav {
        flex-direction: row;
        gap: 20px;
        overflow-x: auto;
        border-right: none;
        border-bottom: 1px solid #80808073;
        height: auto;
        &::-webkit-scrollbar {
            height: 1px;
        }
    }
    .form-nav-item {
        min-height: auto;
        border-radius: 10px;
        padding: 15px;
        background-color: white;
        &-number {
            height: 35px;
            width: 35px;
        }
        &-text {
            display: flex;
            flex-direction: column;
            &-info {
                display: block; // Show on smaller screens
                font-size: 12px;
                color: grey;
                font-weight: 500;
            }
        }
        &.active {
            color: $titleColor;
            border-right: none;
            border-bottom: 2px solid #2b9448;
        }
    }
}
