@import "/src/theme/colors";

.file-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(128, 128, 128, 0.6);
  border-radius: 10px;
  padding: 20px;
  text-align: center;

  label {
    font-size: 14px;
    font-weight: 500;
    color: #5A5A5A;
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    cursor: pointer;

    .file-input-icon {
      font-size: 40px;
      color: $primaryButtonColor;
    }

    .file-input-text {
      font-size: 16px;
      font-weight: 500;
    }

    .file-input-subtext {
      font-size: 14px;
      color: gray;
    }

    .file-input-button {
      background: $primaryButtonColor;
      color: white;
      padding: 7px 20px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      transition: 0.2s ease-in-out;

      &:hover {
        background-color: white;
        color: $primaryButtonColor;
        border: 1px solid $primaryButtonColor;
      }
    }
  }

  &-uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .file-input-file {
      display: flex;
      align-items: center;
      gap: 10px;
      background: #3aadb81a;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      svg {
        font-size: 20px;
        color: $primaryButtonColor;
      }

      .file-size {
        font-size: 12px;
        color: gray;
      }
    }

    .file-input-actions {
      display: flex;
      gap: 15px;

      .upload-btn, .cancel-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 7px 12px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
      }

      .upload-btn {
        background: $primaryButtonColor;
        color: white;
        border: 1px solid $primaryButtonColor;

        &:hover {
          background: white;
          color: $primaryButtonColor;
        }
      }

      .cancel-btn {
        background: #cc0000;
        color: white;
        border: 1px solid #cc0000;

        &:hover {
          background: white;
          color: #cc0000;
        }
      }
    }
  }
}
