.accordion {
    border-radius: 5px;
    color: black;

    .accordion-title {
        background: white;
        color: #5A5A5A;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 15px;
        padding-inline: 30px;
        cursor: pointer;
        outline: none;
        font-size: 24px;
        font-weight: 500;
        border: 1px solid #80808091;
        border-radius: 5px;
        transition: border 0.3s ease;

        &.open {
            border: none;
        }

        .accordion-icon {
            font-size: 14px;
            transition: transform 0.3s ease;

            &.rotate {
                transform: rotate(180deg);
            }
        }
    }

    .accordion-content {
        overflow: hidden;
        background-color: #fff;
        position: relative;
        padding: 0 5px;
        transition: height 0.3s ease;

        .accordion-content-inner {
            margin-block-start: 20px;
        }
    }
}
