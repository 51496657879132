@import "/src/theme/colors";

.user-incident-info {
  position: relative;
  min-height: 400px;
  padding: 20px;
  display: flex;
  gap: 30px;

  &-event {
    width: 85%;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: flex;
        gap: 30px;

        &-title {
          font-weight: 500;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }

      &-right {
        display: flex;
        gap: 20px;

        &-risk_score {
          display: flex;
          gap: 5px;
          font-weight: 500;

          &-key {
          }

          &-value {
            border: 1px solid;
            padding: 10px;
            border-radius: 5px;
          }
        }

        &-action {
        }
      }
    }

    &-mid {
      display: flex;
      margin-block-start: 20px;
      margin-block-end: 40px;
      gap: 30px;

      &-item {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &:nth-child(1) {
          margin-right: auto;
        }

        &-key {
          color: #5a5a5a;
        }

        &-value {
          // font-weight: 500;
        }
      }
    }

    &-bottom {
      &-description {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-key {
          color: #5a5a5a;
        }

        &-value {
        }
      }
    }
  }

  &-people {
    width: 15%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-key {
        color: #5a5a5a;
      }

      &-value {
        &.stakeholder-action-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          border: 1px solid transparent;
          border-radius: 4px;
          transition: background 0.3s ease;
          cursor: pointer;
        }

        &.stakeholder-action-item:hover {
          background: #ecfdff;
        }
      }
    }

    &-add-manager {
      display: flex;
      align-items: center;
      width: max-content;
      border: 1px solid #80808091;
      padding: 10px;
      border-radius: 5px;
      gap: 5px;
      color: gray;

      & svg {
        color: $tertiaryColor;
      }
    }
  }
}

.stakeholder-list {
  max-height: 120px; 
  overflow-y: auto;
}

.stakeholder-trash-icon {
  font-size: 16px;
  color: gray;
  opacity: 0;
  transition: transform 0.3s ease, color 0.3s ease, opacity 0.3s ease;
}

.stakeholder-action-item:hover .stakeholder-trash-icon {
  opacity: 1;
}


.stakeholder-trash-icon:hover {
  color: red;
  transform: scale(1.2);
}
.incident-info-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
}
