.tooltip-box {
  pointer-events: none;
  position: fixed;
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: 14px;
  max-width: 550px;
  word-wrap: break-word;
  white-space: normal;
  transform: translateX(-50%); // Center tooltip horizontally
}

.tooltip-box::after {
  top: var(--tooltipArrowPosition);
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent #E9FDFF transparent transparent;
  // border-top-color: #fff;
  border-width: 10px;
  margin-left: -18px;
}
.tooltip-box::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
  top: var(--tooltipArrowShadowPosition);
  right: 95%;
  z-index: -1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
// .tooltip-box::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   right: 100%;
//   margin-top: -5px;
//   border-width: 10px;
//   border-style: solid;
//   border-color: transparent #E9FDFF transparent transparent;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// }
.tooltip-content {
  text-align: center;
}