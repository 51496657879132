@import "/src/theme/colors";
.attachments {
  &-body {
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.568627451);
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-icon {
      color: $primaryButtonColor;
      font-size: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-drag_text {
      font-size: 18px;
      font-weight: 500;
    }
    &-subtext {
      margin-block-start: 5px;
      margin-block-end: 10px;
    }
    &-button {
      background: $primaryButtonColor;
      padding-block: 7px;
      padding-inline: 20px;
      border-radius: 5px;
      font-size: 14px;
      color: $primaryBackgroundColor;
      cursor: pointer;
      &:hover {
        background-color: $primaryBackgroundColor;
        color: $primaryButtonColor;
        border: 1px solid $primaryButtonColor;
      }
    }

    &-uploaded {
      border-radius: 10px;
      border: 1px solid rgba(128, 128, 128, 0.568627451);
      padding: 40px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-file {
        color: $primaryButtonColor;
        display: flex;
        gap: 5px;
        cursor: pointer;
        padding-block: 15px;
        padding-inline: 30px;
        border-radius: 10px;
        background: #3aadb81a;
        &-icon {
          font-size: 35px;
          display: flex;
          align-items: center;
        }
        &-info {
          display: flex;
          flex-direction: column;
          align-items: start;
          &-name {
            color: black;
            font-weight: 500;
          }
          &-size {
            color: gray;
            font-size: 14px;
          }
        }
      }
      &-action {
        display: flex;
        margin-block-start: 20px;
        gap: 20px;
        &-upload_button {
          display: flex;
          gap: 5px;
          border-radius: 5px;
          padding-block: 5px;
          padding-inline: 10px;
          cursor: pointer;
          border: 1px solid $primaryButtonColor;
          color: $primaryButtonColor;
          &:hover {
            border: none;
            background-color: $primaryButtonColor;
            color: $primaryBackgroundColor;
          }
        }
        &-cancel_button {
          display: flex;
          gap: 5px;
          border-radius: 5px;
          padding-block: 5px;
          padding-inline: 10px;
          cursor: pointer;
          border: 1px solid rgb(153, 0, 0);
          color: rgb(153, 0, 0);
          &:hover {
            border: none;
            background-color: rgb(153, 0, 0);
            color: $primaryBackgroundColor;
          }
        }
      }
    }
  }
}
