@import "/src/theme/colors";

.people-search-dropdown {
  position: relative;
  width: 100%;
  font-size: 14px;
  color: black;

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-label {
    display: block;
    font-size: 14px;
    color: #5a5a5a;
    margin-bottom: 4px;
  }

  &-header {
    height: 65px;
    padding: 10px;
    border: 1px solid #80808091;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: white;
    transition: background 0.3s ease;

    &:hover {
      background: #f7f7f7;
    }

    &-selected {
      display: flex;
      align-items: center;
      gap: 10px;

      &-avatar {
        border-radius: 50%;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        color: white;
      }

      &-text {
        font-size: 16px;
        // font-weight: 500;
      }

      &-placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #aaa;
      }
    }

    &-icon {
      transition: transform 0.3s ease;

      &--rotate {
        transform: rotate(180deg);
      }
    }
  }

 
  &-menu {
    position: absolute;
    top: calc(100% + 4px); 
    left: 0;
    right: 0;
    background: white;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    animation: dropdownFadeIn 0.3s ease;
    display: flex;
    flex-direction: column;

    &-list {
      overflow-y: auto;
      max-height: 200px;

      &-search-container {
        position: sticky;
        top: 0;
        background: white;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        z-index: 5;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          border-bottom: 1px solid #80808091;
        }

        &-icon {
          font-size: 20px;
          font-weight: 300;
          color: #606060;
        }

        &-input {
          border: none;
          outline: none;
          margin: 0;
          background-color: white;
          font-size: 14px;
          flex: 1;
        }
      }

      &-item {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background: #fafbff;
        }

        &-avatar {
          border-radius: 50%;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          color: white;
        }

        &-text {
          font-size: 14px;
        }

        &--no-items {
          padding: 10px;
          text-align: center;
          color: #808080;
        }

        &--view-more {
          padding: 10px;
          text-align: center;
          color: #808080;
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: $primaryColor;
          }
        }

        &--loading {
          padding: 10px;
          text-align: center;
          color: #808080;
        }
      }
    }
  }
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
