@import '/src/theme/colors';

.text-input {
  border: 1px solid #80808091;
  height: 65px;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    text-align: left;
    font-size: 14px;
    color: #5A5A5A;
  }

  input {
    background-color: white;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }

  input:focus {
    // box-shadow: 0px 0px 2px 0px #80808091;
    padding: 2px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
