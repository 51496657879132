@import "/src/theme/colors";

.responsive-table-container {
  overflow-x: auto;
  box-shadow: 0px 0px 2px 1px rgb(166 166 166);
  border-radius: 10px;
  color: black;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 400px;
  & img {
    margin-left: 10px;
    width: 15px;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0bbbb;
    border-radius: 4px;
  }

  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          background: #f4f4f4;
          color: #5a5a5a;
          font-weight: 500;
          padding-inline: 10px;
          padding-block: 20px;
          white-space: nowrap;

          &:first-child {
            left: 0;
            z-index: 11;
          }

          &:not(:first-child) {
            z-index: 10;
          }
        }
      }
    }

    th,
    td {
      text-align: left;
      padding-inline: 10px;
      padding-block: 20px;
      white-space: nowrap;
    }

    tbody {
      tr {
        border-top: 1px solid #ccc;
        &:hover {
          background-color: #e8f7f9;
          td.sticky {
            background: #e8f7f9;
          }
        }
      }
    }

    td.sticky {
      position: sticky;
      left: 0;
      z-index: 5;
      background: white;
    }
  }
}

.clickable-row {
  cursor: pointer;
  &:hover {
    transition: background-color 0.2s ease-in-out;
  }
}
