@import '/src/theme/colors';

.checkbox-input-question {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: min-content;
  border: 1px solid #80808091;
  border-radius: 10px;
  padding: 10px;
  color: black;

  &_text {
    font-size: 14px;
    text-align: left;
    color: #5A5A5A;
  }

  &_checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.form__checkbox-input {
    display: none;
  }
  
  .form__checkbox-label {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
  
  .form__checkbox-box {
    height: 20px;
    width: 20px;
    border: 2px solid $tertiaryColor;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
    color: $tertiaryColor;
  }
  
  .form__checkbox-box::after {
    content: "";
    display: none;
  }
  
  .form__checkbox-input:checked + .form__checkbox-label .form__checkbox-box::after {
    content: "\2713"; 
    display: block;
  }
  
  .form__checkbox-text {
    font-size: 14px;
    color: #5A5A5A;
  }
  
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }