@import "/src/theme/colors";

.user-incident-details {
  &-title {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
   
    &-text {
      color: $titleColor;
      font-size: 24px;
    }
    &-icon {
      color: black;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s, color 0.3s, cursor 0.3s;
      &:hover {
        transform: scale(1.1);
        color: $tertiaryColor;
      }
    }
  }

  &-body {
    color: black;
    background: white;
    border: 1px solid #80808091;
    border-radius: 10px;
  }
}
