@import "/src/theme/colors";
.dropdown-button {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      padding: 10px 15px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-size: 16px;
  
      &:hover {
        // box-shadow: 0px 0px 7px 1px #daf0f2;
      }
    }
  
    .chevron-icon {
      margin-left: 10px;
      transition: transform 0.3s ease;
  
      &.rotate {
        transform: rotate(180deg);
      }
    }
  
    &__list {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 5px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      list-style-type: none;
      padding: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
      pointer-events: none; 
    }
  
    &__list-item {
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.2s ease;
      border-block-end: 1px solid #e1e1e1;
      color: #808080;
  
      &:hover {
        background-color: #f5f5f5;
      }
    }
  
    &__list.open {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      pointer-events: auto;
      z-index: 3;
    }
  }
  