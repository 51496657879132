@import "/src/theme/colors";

.admin_incident_reports {
  &-modal {
    &-title {
      font-weight: 500;
      font-size: 18px;
      margin-block-end: 10px;
    }

    &-content {
      &-message {
        color: $primaryButtonColor;
        margin-block: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      }

      &-form {
        &-label {
          font-weight: 500;
          font-size: 14px;
          margin-block-end: 5px;
        }

        &-help_text {
          font-size: 12px;
          margin-inline-start: 5px;
          color: #5a5a5a;
        }

        &-input {
          background: #fafbff;
          margin: 0;
          margin-block-start: 5px;
          margin-block-end: 15px;
        }
      }

      &-body {
        background: $primaryBackgroundColor;
        border-radius: 5px;
        border: 1px dashed #9f9f9f;
        padding-block: 15px;
        margin-block-start: 5px;

        &-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;

          & button {
            font-weight: 400;
            width: 120px;
          }

          &-icon {
            color: $primaryButtonColor;
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &-drag_text {
            font-size: 14px;
            font-weight: 500;
          }

          &-subtext {
            font-size: 14px;
          }

          &-button {
            text-decoration: underline;
            font-size: 14px;
            color: $primaryButtonColor;
            cursor: pointer;

            &:hover {
              background-color: $primaryBackgroundColor;
              color: $primaryButtonColor;
            }
          }
        }

        &-uploaded {
          display: flex;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &-file {
            color: $primaryButtonColor;
            display: flex;
            gap: 5px;
            cursor: pointer;
            padding-block: 15px;
            padding-inline: 30px;
            border-radius: 10px;
            background: #3aadb81a;

            &-icon {
              font-size: 35px;
              display: flex;
              align-items: center;
            }

            &-info {
              display: flex;
              flex-direction: column;
              align-items: start;

              &-name {
                color: black;
                font-weight: 500;
              }

              &-size {
                color: gray;
                font-size: 14px;
              }
            }
          }

          &-action {
            display: flex;
            gap: 10px;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            padding-inline: 5%;

            &-error-message {
              font-size: 14px;
              text-align: center;
              color: #990000;
            }

            &-upload_button {
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid $primaryButtonColor;
              color: $primaryButtonColor;

              &:hover {
                border: none;
                background-color: $primaryButtonColor;
                color: $primaryBackgroundColor;
              }
            }

            &-cancel_button {
              width: 100px;
              display: flex;
              gap: 5px;
              border-radius: 5px;
              padding-block: 5px;
              padding-inline: 10px;
              cursor: pointer;
              border: 1px solid rgb(153, 0, 0);
              color: rgb(153, 0, 0);

              &:hover {
                border: none;
                background-color: rgb(153, 0, 0);
                color: $primaryBackgroundColor;
              }
            }
          }
        }
      }
    }

    &-bottom {
      display: flex;
      gap: 20px;
      margin-block-start: 20px;

      & button {
        font-size: 12px;
        width: 130px;
      }
    }
  }

  &-title {
    margin-bottom: 30px;
    font-size: 24px;
    color: $titleColor;
    font-weight: 600;
  }

  &-tabs {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid $buttonHoverColor;
    margin-block-end: 25px;

    &-tab {
      padding: 1px 50px;
      border-right: 1px solid #3aadb8;
      cursor: pointer;

      &.active {
        color: #05167c;
        background-color: transparent;
        border-bottom: 3px solid $primaryButtonColor; // Example of additional styling for the active tab
      }

      // &:hover {
      //     color: $buttonHoverColor;
      // }
    }
  }

  &-body {
    padding-inline-end: 2%;
    border-radius: 10px;

    &-tab {
      &-content {
        display: flex;
        flex-direction: column;
        gap: 30px;

        &-top {
          display: flex;
          align-items: end;
          flex-direction: row-reverse;
          gap: 20px;

          &-search_box {
            width: 40%;
            height: 35px;
          }
        }

        &-table {
          margin-block-start: 30px;
          // padding: 20px;
          border-radius: 10px;
          box-shadow: 0px 0px 7px 1px #d9d9d9;

          &-head {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: gray;
            padding: 25px 20px;

            &-item {
              display: flex;
              width: 25%;
              text-align: center;
              justify-content: center;

              & svg {
                border: 1px solid #8080803d;
                border-radius: 5px;
                width: 40px;
                height: 35px;
                padding: 5px;
                transition: transform 0.3s, color 0.3s, cursor 0.3s;

                &.active {
                  color: red;
                  background-color: transparent;

                  &:hover {
                    border: 1px solid #ff000061;
                    cursor: pointer;
                    transform: scale(1.1);
                  }
                }

                &.inactive {
                  color: grey;
                }
              }
            }

            &-item:nth-child(1) {
              justify-content: flex-start;
            }
          }

          &-body {
            &-row {
              display: flex;
              justify-content: space-between;
              border-top: solid 1px #d9d9d9;

              &-item {
                display: flex;
                width: 25%;
                padding: 15px 20px;
                align-items: center;
                justify-content: center;
                position: relative;
                color: #5a5a5a;

                &-text {
                  display: flex;
                  flex-direction: column;

                  &-main {
                    font-weight: 500;
                    color: black;
                  }

                  &-sub {
                    font-size: 14px;
                    color: #6eaadc;
                  }

                  & input {
                    margin: 0;
                    background: white;
                    box-shadow: 0px 0px 1px 1px rgb(205, 207, 219);
                  }
                }

                &-button {
                  gap: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: color 0.3s ease;
                  font-weight: 400;

                  svg {
                    transition: transform 0.3s ease;
                  }

                  &.open {
                    svg {
                      transform: rotate(180deg);
                    }
                  }
                }

                &-dropdown-menu {
                  position: absolute;
                  bottom: -40px;
                  left: 5px;
                  display: flex;
                  flex-direction: column;
                  box-shadow: 0px 0px 7px 1px #d9d9d9;
                  background: white;
                  z-index: 1;
                  border-radius: 5px;
                  padding: 5px;
                  opacity: 0;
                  visibility: hidden;
                  transform: translateY(-10px);
                  transition: opacity 0.3s ease, transform 0.3s ease,
                    visibility 0.3s ease;

                  &.open {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                  }

                  &-item {
                    padding: 5px;
                    cursor: pointer;
                    border-radius: 5px;

                    &:hover {
                      background: $secondaryColor;
                      color: black;
                    }
                  }

                  &-item:nth-child(1) {
                    border-bottom: solid 1px #d9d9d98a;
                  }
                }
              }

              &-item:nth-child(1) {
                display: flex;
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }

    & button {
      width: 100px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 16px;
    }
  }
}

.link {
  color: $tertiaryColor;
  text-decoration: underline;
  transition: transform 0.3s, color 0.3s, cursor 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.incident-tooltip {
  background-color: #E9FDFF;
  padding: 10px;
  border-radius: 10px;

  &-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    min-width: 450px;
    &-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #C4F0F6;
      padding: 15px 20px;
      border-radius: 10px 10px 0px 0px;

      &-row {
        display: flex;
        justify-content: space-between;

        &-item {
          display: flex;
          gap: 5px;

          &-key {
            font-weight: 500;
            color: #5A5A5A;
          }

          &-value {
            font-weight: 500;
          }
        }
      }
    }

    &-body {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 20px;
      gap: 10px;

      &-details {
        display: flex;
        flex-direction: column;
        text-align: left;
        &-title {
          font-weight: 500;      
        }
      }
    }
  }
}