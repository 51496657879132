@import "/src/theme/colors";

.people-add-search {
  position: relative;
  font-size: 14px;
  color: black;

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // Common dimensions for both states
  //   $common-width: 250px;
  $common-height: 45px;

  // The initial button to add people
  &-add-button {
    display: flex;
    align-items: center;
    // width: $common-width;
    height: $common-height;
    border: 1px solid #80808091;
    padding: 0 10px;
    border-radius: 5px;
    gap: 15px;
    color: gray;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #f7f7f7;
    }

    &-icon {
      color: $tertiaryColor;
      font-size: 20px;
    }

    &-label {
      font-size: 16px;
    }
  }

  &-input-container {
    position: relative;
    // width: $common-width;
    height: $common-height;

    &-input {
      margin: 0;
      background: white;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      border: 1px solid #80808091;
      border-radius: 5px;
      font-size: 14px;
      outline: none;
      box-sizing: border-box;
      transition: all 0.3s ease;
    }
  }

  &-dropdown-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    animation: dropdownFadeIn 0.3s ease;
    display: flex;
    flex-direction: column;
  }

  &-dropdown-menu-list {
    overflow-y: auto;
    max-height: 200px;

    &-search-container {
      position: sticky;
      top: 0;
      background: white;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 5px;
      z-index: 5;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        border-bottom: 1px solid #80808091;
      }

      &-icon {
        font-size: 14px;
        color: #5a5a5a;
      }

      &-input {
        margin: 0;
        background: white;
        border: none;
        outline: none;
        font-size: 14px;
        flex: 1;
      }
    }

    &-item {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background: #fafbff;
      }

      &-avatar {
        border-radius: 50%;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        background: #cccccc;
        color: white;
      }

      &-text {
        font-size: 14px;
      }

      &--no-items {
        text-align: center;
        color: gray;
      }

      &--view-more,
      &--loading {
        text-align: center;
        font-size: 14px;
        color: gray;
      }
    }
  }
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
