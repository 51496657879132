.table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;

    &-pagination {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    &-dropdown {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-inline-end: 10px;
    }
  }