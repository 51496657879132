@import '/src/theme/colors';
.comments {
  &-input {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    border: 1px solid #80808091;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    &-user {
      display: flex;
      align-items: center;
      gap: 10px;

      &-name {
        font-weight: 500;
      }
      &-avatar {
        color: gray;
      }
    }
    &-textbox {
      width: 100%;
      textarea {
        width: 100%;
        padding: 8px;
        border: none;
        border-bottom: 1px solid #80808091;
        resize: none;
        min-height: 50px;
        outline: none;
      }
    }
    &-button {
      width: 100%;
      display: flex;
      justify-content: end;

      &:hover {
      }
    }
  }

  &-list {
    padding-inline-start: 25px;
    padding-block-start: 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      display: flex;
      flex-direction: column;
      &-user {
        display: flex;
        gap: 10px;
        align-items: center;
        &-avatar {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          color: white;
          & img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        &-name {
          font-weight: 500;
        }
        &-time {
          font-size: 14px;
          color: grey;
        }
      }
      &-comment_text {
        padding-left: 45px;
        padding-block-end: 10px;
      }
      &-bottom {
        padding-left: 45px;
        display: flex;
        gap: 20px;
        align-items: center;
        font-weight: 500;
      }
    }
  }
}
